import { ICountries } from './data-service.interfaces';

export class DataServiceConstants {
  public static countries: ICountries = {
    AF: { code: 'AF', name: 'Afghanistan', prefix: '++93', currency_code: 'AFN', img: 'assets/images/flags/AF.png' },
    AX: { code: 'AX', name: 'Aland Islands', prefix: '++93', currency_code: 'EUR', img: 'assets/images/flags/AX.png' },
    AL: { code: 'AL', name: 'Albania', prefix: '++355', currency_code: 'ALL', img: 'assets/images/flags/AL.png' },
    DZ: { code: 'DZ', name: 'Algeria', prefix: '++213', currency_code: 'DZD', img: 'assets/images/flags/DZ.png' },
    AS: { code: 'AS', name: 'American Samoa', prefix: '++1', currency_code: 'EUR', img: 'assets/images/flags/AS.png' },
    AD: { code: 'AD', name: 'Andorra', prefix: '++376', currency_code: 'EUR', img: 'assets/images/flags/AD.png' },
    AO: { code: 'AO', name: 'Angola', prefix: '++244', currency_code: 'AOA', img: 'assets/images/flags/AO.png' },
    AI: { code: 'AI', name: 'Anguilla', prefix: '++1', currency_code: 'XCD', img: 'assets/images/flags/AI.png' },
    AQ: { code: 'AQ', name: 'Antarctica', prefix: '++93', currency_code: 'AQD', img: 'assets/images/flags/AQ.png' },
    AG: { code: 'AG', name: 'Antigua and Barbuda', prefix: '++1', currency_code: 'XCD', img: 'assets/images/flags/AG.png' },
    AR: { code: 'AR', name: 'Argentina', prefix: '++54', currency_code: 'ARS', img: 'assets/images/flags/AR.png' },
    AM: { code: 'AM', name: 'Armenia', prefix: '++374', currency_code: 'AMD', img: 'assets/images/flags/AM.png' },
    AW: { code: 'AW', name: 'Aruba', prefix: '++297', currency_code: 'ANG', img: 'assets/images/flags/AW.png' },
    AU: { code: 'AU', name: 'Australia', prefix: '++61', currency_code: 'AUD', img: 'assets/images/flags/AU.png' },
    AT: { code: 'AT', name: 'Austria', prefix: '++43', currency_code: 'EUR', img: 'assets/images/flags/AT.png' },
    AZ: { code: 'AZ', name: 'Azerbaijan', prefix: '++994', currency_code: 'AZN', img: 'assets/images/flags/AZ.png' },
    BS: { code: 'BS', name: 'Bahamas', prefix: '++1', currency_code: 'BSD', img: 'assets/images/flags/BS.png' },
    BH: { code: 'BH', name: 'Bahrain', prefix: '++973', currency_code: 'BHD', img: 'assets/images/flags/BH.png' },
    BD: { code: 'BD', name: 'Bangladesh', prefix: '++880', currency_code: 'BDT', img: 'assets/images/flags/BD.png' },
    BB: { code: 'BB', name: 'Barbados', prefix: '++1', currency_code: 'BBD', img: 'assets/images/flags/BB.png' },
    BY: { code: 'BY', name: 'Belarus', prefix: '++375', currency_code: 'BYR', img: 'assets/images/flags/BY.png' },
    BE: { code: 'BE', name: 'Belgium', prefix: '++32', currency_code: 'EUR', img: 'assets/images/flags/BE.png' },
    BZ: { code: 'BZ', name: 'Belize', prefix: '++501', currency_code: 'BZD', img: 'assets/images/flags/BZ.png' },
    BJ: { code: 'BJ', name: 'Benin', prefix: '++229', currency_code: 'XOF', img: 'assets/images/flags/BJ.png' },
    BM: { code: 'BM', name: 'Bermuda', prefix: '++1', currency_code: 'BMD', img: 'assets/images/flags/BM.png' },
    BT: { code: 'BT', name: 'Bhutan', prefix: '++975', currency_code: 'INR', img: 'assets/images/flags/BT.png' },
    BO: { code: 'BO', name: 'Bolivia', prefix: '++591', currency_code: 'BOB', img: 'assets/images/flags/BO.png' },
    BA: { code: 'BA', name: 'Bosnia and Herzegovina', prefix: '++387', currency_code: 'BAM', img: 'assets/images/flags/BA.png' },
    BW: { code: 'BW', name: 'Botswana', prefix: '++267', currency_code: 'BWP', img: 'assets/images/flags/BW.png' },
    BV: { code: 'BV', name: 'Bouvet Island', prefix: '++93', currency_code: 'NOK', img: 'assets/images/flags/BV.png' },
    BR: { code: 'BR', name: 'Brazil', prefix: '++55', currency_code: 'BRL', img: 'assets/images/flags/BR.png' },
    IO: { code: 'IO', name: 'British Indian Ocean Territory', prefix: '++1', currency_code: 'USD', img: 'assets/images/flags/IO.png' },
    BN: { code: 'BN', name: 'Brunei Darussalam', prefix: '++673', currency_code: 'BND', img: 'assets/images/flags/BN.png' },
    BG: { code: 'BG', name: 'Bulgaria', prefix: '++359', currency_code: 'BGN', img: 'assets/images/flags/BG.png' },
    BF: { code: 'BF', name: 'Burkina Faso', prefix: '++226', currency_code: 'XOF', img: 'assets/images/flags/BF.png' },
    BI: { code: 'BI', name: 'Burundi', prefix: '++257', currency_code: 'BIF', img: 'assets/images/flags/BI.png' },
    KH: { code: 'KH', name: 'Cambodia', prefix: '++855', currency_code: 'KHR', img: 'assets/images/flags/KH.png' },
    CM: { code: 'CM', name: 'Cameroon', prefix: '++237', currency_code: 'XAF', img: 'assets/images/flags/CM.png' },
    CA: { code: 'CA', name: 'Canada', prefix: '++1', currency_code: 'CAD', img: 'assets/images/flags/CA.png' },
    CV: { code: 'CV', name: 'Cape Verde', prefix: '++238', currency_code: 'CVE', img: 'assets/images/flags/CV.png' },
    KY: { code: 'KY', name: 'Cayman Islands', prefix: '++1', currency_code: 'KYD', img: 'assets/images/flags/KY.png' },
    CF: { code: 'CF', name: 'Central African Republic', prefix: '++236', currency_code: 'XAF', img: 'assets/images/flags/CF.png' },
    TD: { code: 'TD', name: 'Chad', prefix: '++235', currency_code: 'XAF', img: 'assets/images/flags/TD.png' },
    CL: { code: 'CL', name: 'Chile', prefix: '++56', currency_code: 'CLP', img: 'assets/images/flags/CL.png' },
    CN: { code: 'CN', name: 'China', prefix: '++86', currency_code: 'CNY', img: 'assets/images/flags/CN.png' },
    CX: { code: 'CX', name: 'Christmas Island', prefix: '++93', currency_code: 'AUD', img: 'assets/images/flags/CX.png' },
    CC: { code: 'CC', name: 'Cocos (Keeling) Islands', prefix: '++93', currency_code: 'AUD', img: 'assets/images/flags/CC.png' },
    CO: { code: 'CO', name: 'Colombia', prefix: '++57', currency_code: 'COP', img: 'assets/images/flags/CO.png' },
    KM: { code: 'KM', name: 'Comoros', prefix: '++269', currency_code: 'KMF', img: 'assets/images/flags/KM.png' },
    CG: { code: 'CG', name: 'Congo', prefix: '++242', currency_code: 'XAF', img: 'assets/images/flags/CG.png' },
    CD: {
      code: 'CD',
      name: 'Congo, the Democratic Republic of the',
      prefix: '++243',
      currency_code: 'CDF',
      img: 'assets/images/flags/CD.png',
    },
    CK: { code: 'CK', name: 'Cook Islands', prefix: '++682', currency_code: 'NZD', img: 'assets/images/flags/CK.png' },
    CR: { code: 'CR', name: 'Costa Rica', prefix: '++506', currency_code: 'CRC', img: 'assets/images/flags/CR.png' },
    CI: { code: 'CI', name: 'Cote D Ivoire', prefix: '++93', currency_code: 'XOF', img: 'assets/images/flags/CI.png' },
    HR: { code: 'HR', name: 'Croatia', prefix: '++385', currency_code: 'HRK', img: 'assets/images/flags/HR.png' },
    CU: { code: 'CU', name: 'Cuba', prefix: '++53', currency_code: 'CUP', img: 'assets/images/flags/CU.png' },
    CW: { code: 'CW', name: 'Curacao', prefix: '++599', currency_code: 'ANG', img: 'assets/images/flags/CW.png' },
    CY: { code: 'CY', name: 'Cyprus', prefix: '++357', currency_code: 'CYP', img: 'assets/images/flags/CY.png' },
    CZ: { code: 'CZ', name: 'Czech Republic', prefix: '++420', currency_code: 'CZK', img: 'assets/images/flags/CZ.png' },
    DK: { code: 'DK', name: 'Denmark', prefix: '++45', currency_code: 'DKK', img: 'assets/images/flags/DK.png' },
    DJ: { code: 'DJ', name: 'Djibouti', prefix: '++253', currency_code: 'DJF', img: 'assets/images/flags/DJ.png' },
    DM: { code: 'DM', name: 'Dominica', prefix: '++1', currency_code: 'XCD', img: 'assets/images/flags/DM.png' },
    DO: { code: 'DO', name: 'Dominican Republic', prefix: '++1', currency_code: 'DOP', img: 'assets/images/flags/DO.png' },
    EC: { code: 'EC', name: 'Ecuador', prefix: '++593', currency_code: 'ECS', img: 'assets/images/flags/EC.png' },
    EG: { code: 'EG', name: 'Egypt', prefix: '++20', currency_code: 'EGP', img: 'assets/images/flags/EG.png' },
    SV: { code: 'SV', name: 'El Salvador', prefix: '++503', currency_code: 'SVC', img: 'assets/images/flags/SV.png' },
    GQ: { code: 'GQ', name: 'Equatorial Guinea', prefix: '++240', currency_code: 'XAF', img: 'assets/images/flags/GQ.png' },
    ER: { code: 'ER', name: 'Eritrea', prefix: '++291', currency_code: 'ETB', img: 'assets/images/flags/ER.png' },
    EE: { code: 'EE', name: 'Estonia', prefix: '++372', currency_code: 'EEK', img: 'assets/images/flags/EE.png' },
    ET: { code: 'ET', name: 'Ethiopia', prefix: '++251', currency_code: 'ETB', img: 'assets/images/flags/ET.png' },
    FK: { code: 'FK', name: 'Falkland Islands (Malvinas)', prefix: '++500', currency_code: 'FKP', img: 'assets/images/flags/FK.png' },
    FO: { code: 'FO', name: 'Faroe Islands', prefix: '++298', currency_code: 'DKK', img: 'assets/images/flags/FO.png' },
    FJ: { code: 'FJ', name: 'Fiji', prefix: '++679', currency_code: 'FJD', img: 'assets/images/flags/FJ.png' },
    FI: { code: 'FI', name: 'Finland', prefix: '++358', currency_code: 'EUR', img: 'assets/images/flags/FI.png' },
    FR: { code: 'FR', name: 'France', prefix: '++33', currency_code: 'EUR', img: 'assets/images/flags/FR.png' },
    GF: { code: 'GF', name: 'French Guiana', prefix: '++594', currency_code: 'EUR', img: 'assets/images/flags/GF.png' },
    PF: { code: 'PF', name: 'French Polynesia', prefix: '++689', currency_code: 'XPF', img: 'assets/images/flags/PF.png' },
    TF: { code: 'TF', name: 'French Southern Territories', prefix: '++93', currency_code: 'EUR', img: 'assets/images/flags/TF.png' },
    GA: { code: 'GA', name: 'Gabon', prefix: '++241', currency_code: 'XAF', img: 'assets/images/flags/GA.png' },
    GM: { code: 'GM', name: 'Gambia', prefix: '++220', currency_code: 'GMD', img: 'assets/images/flags/GM.png' },
    GE: { code: 'GE', name: 'Georgia', prefix: '++995', currency_code: 'GEL', img: 'assets/images/flags/GE.png' },
    DE: { code: 'DE', name: 'Germany', prefix: '++49', currency_code: 'EUR', img: 'assets/images/flags/DE.png' },
    GH: { code: 'GH', name: 'Ghana', prefix: '++233', currency_code: 'GHS', img: 'assets/images/flags/GH.png' },
    GI: { code: 'GI', name: 'Gibraltar', prefix: '++350', currency_code: 'GIP', img: 'assets/images/flags/GI.png' },
    GR: { code: 'GR', name: 'Greece', prefix: '++30', currency_code: 'EUR', img: 'assets/images/flags/GR.png' },
    GL: { code: 'GL', name: 'Greenland', prefix: '++299', currency_code: 'DKK', img: 'assets/images/flags/GL.png' },
    GD: { code: 'GD', name: 'Grenada', prefix: '++1', currency_code: 'XCD', img: 'assets/images/flags/GD.png' },
    GP: { code: 'GP', name: 'Guadeloupe', prefix: '++590', currency_code: 'EUR', img: 'assets/images/flags/GP.png' },
    GU: { code: 'GU', name: 'Guam', prefix: '++1', currency_code: 'USD', img: 'assets/images/flags/GU.png' },
    GT: { code: 'GT', name: 'Guatemala', prefix: '++502', currency_code: 'GTQ', img: 'assets/images/flags/GT.png' },
    GG: { code: 'GG', name: 'Guernsey', prefix: '++224', currency_code: 'GGP', img: 'assets/images/flags/GG.png' },
    GN: { code: 'GN', name: 'Guinea', prefix: '++224', currency_code: 'GNF', img: 'assets/images/flags/GN.png' },
    GW: { code: 'GW', name: 'Guinea-Bissau', prefix: '++245', currency_code: 'XOF', img: 'assets/images/flags/GW.png' },
    GY: { code: 'GY', name: 'Guyana', prefix: '++592', currency_code: 'GYD', img: 'assets/images/flags/GY.png' },
    HT: { code: 'HT', name: 'Haiti', prefix: '++509', currency_code: 'HTG', img: 'assets/images/flags/HT.png' },
    HM: {
      code: 'HM',
      name: 'Heard Island and Mcdonald Islands',
      prefix: '++93',
      currency_code: 'AUD',
      img: 'assets/images/flags/HM.png',
    },
    VA: { code: 'VA', name: 'Holy See (Vatican City State)', prefix: '++93', currency_code: 'EUR', img: 'assets/images/flags/VA.png' },
    HN: { code: 'HN', name: 'Honduras', prefix: '++504', currency_code: 'HNL', img: 'assets/images/flags/HN.png' },
    HK: { code: 'HK', name: 'Hong Kong', prefix: '++852', currency_code: 'HKD', img: 'assets/images/flags/HK.png' },
    HU: { code: 'HU', name: 'Hungary', prefix: '++36', currency_code: 'HUF', img: 'assets/images/flags/HU.png' },
    IS: { code: 'IS', name: 'Iceland', prefix: '++354', currency_code: 'ISK', img: 'assets/images/flags/IS.png' },
    IN: { code: 'IN', name: 'India', prefix: '++91', currency_code: 'INR', img: 'assets/images/flags/IN.png' },
    ID: { code: 'ID', name: 'Indonesia', prefix: '++62', currency_code: 'IDR', img: 'assets/images/flags/ID.png' },
    IR: { code: 'IR', name: 'Iran, Islamic Republic of', prefix: '++98', currency_code: 'IRR', img: 'assets/images/flags/IR.png' },
    IQ: { code: 'IQ', name: 'Iraq', prefix: '++964', currency_code: 'IQD', img: 'assets/images/flags/IQ.png' },
    IE: { code: 'IE', name: 'Ireland', prefix: '++353', currency_code: 'EUR', img: 'assets/images/flags/IE.png' },
    IM: { code: 'IM', name: 'Isle Of Man', prefix: '++93', currency_code: 'GBP', img: 'assets/images/flags/IM.png' },
    IL: { code: 'IL', name: 'Israel', prefix: '++972', currency_code: 'ILS', img: 'assets/images/flags/IL.png' },
    IT: { code: 'IT', name: 'Italy', prefix: '++39', currency_code: 'EUR', img: 'assets/images/flags/IT.png' },
    JM: { code: 'JM', name: 'Jamaica', prefix: '++1', currency_code: 'JMD', img: 'assets/images/flags/JM.png' },
    JP: { code: 'JP', name: 'Japan', prefix: '++81', currency_code: 'JPY', img: 'assets/images/flags/JP.png' },
    JE: { code: 'JE', name: 'Jersey', prefix: '++93', currency_code: 'GBP', img: 'assets/images/flags/JE.png' },
    JO: { code: 'JO', name: 'Jordan', prefix: '++962', currency_code: 'JOD', img: 'assets/images/flags/JO.png' },
    KZ: { code: 'KZ', name: 'Kazakhstan', prefix: '++7', currency_code: 'KZT', img: 'assets/images/flags/KZ.png' },
    KE: { code: 'KE', name: 'Kenya', prefix: '++254', currency_code: 'KES', img: 'assets/images/flags/KE.png' },
    KI: { code: 'KI', name: 'Kiribati', prefix: '++686', currency_code: 'AUD', img: 'assets/images/flags/KI.png' },
    KR: { code: 'KR', name: 'Korea, Republic of', prefix: '++93', currency_code: 'KRW', img: 'assets/images/flags/KR.png' },
    XK: { code: 'XK', name: 'Kosovo', prefix: '++383', currency_code: 'EUR', img: 'assets/images/flags/XK.png' },
    KW: { code: 'KW', name: 'Kuwait', prefix: '++965', currency_code: 'KWD', img: 'assets/images/flags/KW.png' },
    KG: { code: 'KG', name: 'Kyrgyzstan', prefix: '++996', currency_code: 'KGS', img: 'assets/images/flags/KG.png' },
    LA: { code: 'LA', name: 'Lao Peoples Democratic Republic', prefix: '++856', currency_code: 'LAK', img: 'assets/images/flags/LA.png' },
    LV: { code: 'LV', name: 'Latvia', prefix: '++371', currency_code: 'LVL', img: 'assets/images/flags/LV.png' },
    LB: { code: 'LB', name: 'Lebanon', prefix: '++961', currency_code: 'LBP', img: 'assets/images/flags/LB.png' },
    LS: { code: 'LS', name: 'Lesotho', prefix: '++266', currency_code: 'LSL', img: 'assets/images/flags/LS.png' },
    LR: { code: 'LR', name: 'Liberia', prefix: '++231', currency_code: 'LRD', img: 'assets/images/flags/LR.png' },
    LY: { code: 'LY', name: 'Libyan Arab Jamahiriya', prefix: '++218', currency_code: 'LYD', img: 'assets/images/flags/LY.png' },
    LI: { code: 'LI', name: 'Liechtenstein', prefix: '++423', currency_code: 'CHF', img: 'assets/images/flags/LI.png' },
    LT: { code: 'LT', name: 'Lithuania', prefix: '++370', currency_code: 'LTL', img: 'assets/images/flags/LT.png' },
    LU: { code: 'LU', name: 'Luxembourg', prefix: '++352', currency_code: 'EUR', img: 'assets/images/flags/LU.png' },
    MO: { code: 'MO', name: 'Macao', prefix: '++853', currency_code: 'MOP', img: 'assets/images/flags/MO.png' },
    MK: {
      code: 'MK',
      name: 'Macedonia, the Former Yugoslav Republic of',
      prefix: '++389',
      currency_code: 'MKD',
      img: 'assets/images/flags/MK.png',
    },
    MG: { code: 'MG', name: 'Madagascar', prefix: '++261', currency_code: 'MGA', img: 'assets/images/flags/MG.png' },
    MW: { code: 'MW', name: 'Malawi', prefix: '++265', currency_code: 'MWK', img: 'assets/images/flags/MW.png' },
    MY: { code: 'MY', name: 'Malaysia', prefix: '++60', currency_code: 'MYR', img: 'assets/images/flags/MY.png' },
    MV: { code: 'MV', name: 'Maldives', prefix: '++960', currency_code: 'MVR', img: 'assets/images/flags/MV.png' },
    ML: { code: 'ML', name: 'Mali', prefix: '++223', currency_code: 'XOF', img: 'assets/images/flags/ML.png' },
    MT: { code: 'MT', name: 'Malta', prefix: '++356', currency_code: 'MTL', img: 'assets/images/flags/MT.png' },
    MH: { code: 'MH', name: 'Marshall Islands', prefix: '++692', currency_code: 'USD', img: 'assets/images/flags/MH.png' },
    MQ: { code: 'MQ', name: 'Martinique', prefix: '++596', currency_code: 'EUR', img: 'assets/images/flags/MQ.png' },
    MR: { code: 'MR', name: 'Mauritania', prefix: '++222', currency_code: 'MRO', img: 'assets/images/flags/MR.png' },
    MU: { code: 'MU', name: 'Mauritius', prefix: '++230', currency_code: 'MUR', img: 'assets/images/flags/MU.png' },
    YT: { code: 'YT', name: 'Mayotte', prefix: '++262', currency_code: 'EUR', img: 'assets/images/flags/YT.png' },
    MX: { code: 'MX', name: 'Mexico', prefix: '++52', currency_code: 'MXN', img: 'assets/images/flags/MX.png' },
    FM: { code: 'FM', name: 'Micronesia, Federated States of', prefix: '++691', currency_code: 'USD', img: 'assets/images/flags/FM.png' },
    MD: { code: 'MD', name: 'Moldova, Republic of', prefix: '++373', currency_code: 'MDL', img: 'assets/images/flags/MD.png' },
    MC: { code: 'MC', name: 'Monaco', prefix: '++377', currency_code: 'EUR', img: 'assets/images/flags/MC.png' },
    MN: { code: 'MN', name: 'Mongolia', prefix: '++976', currency_code: 'MNT', img: 'assets/images/flags/MN.png' },
    ME: { code: 'ME', name: 'Montenegro', prefix: '++382', currency_code: 'EUR', img: 'assets/images/flags/ME.png' },
    MS: { code: 'MS', name: 'Montserrat', prefix: '++1', currency_code: 'XCD', img: 'assets/images/flags/MS.png' },
    MA: { code: 'MA', name: 'Morocco', prefix: '++212', currency_code: 'MAD', img: 'assets/images/flags/MA.png' },
    MZ: { code: 'MZ', name: 'Mozambique', prefix: '++258', currency_code: 'MZN', img: 'assets/images/flags/MZ.png' },
    MM: { code: 'MM', name: 'Myanmar', prefix: '++95', currency_code: 'MMK', img: 'assets/images/flags/MM.png' },
    NA: { code: 'NA', name: 'Namibia', prefix: '++264', currency_code: 'NAD', img: 'assets/images/flags/NA.png' },
    NR: { code: 'NR', name: 'Nauru', prefix: '++674', currency_code: 'AUD', img: 'assets/images/flags/NR.png' },
    NP: { code: 'NP', name: 'Nepal', prefix: '++977', currency_code: 'NPR', img: 'assets/images/flags/NP.png' },
    NL: { code: 'NL', name: 'Netherlands', prefix: '++31', currency_code: 'EUR', img: 'assets/images/flags/NL.png' },
    AN: { code: 'AN', name: 'Netherlands Antilles', prefix: '++599', currency_code: 'ANG', img: 'assets/images/flags/AN.png' },
    NC: { code: 'NC', name: 'New Caledonia', prefix: '++687', currency_code: 'XPF', img: 'assets/images/flags/NC.png' },
    NZ: { code: 'NZ', name: 'New Zealand', prefix: '++64', currency_code: 'NZD', img: 'assets/images/flags/NZ.png' },
    NI: { code: 'NI', name: 'Nicaragua', prefix: '++505', currency_code: 'NIO', img: 'assets/images/flags/NI.png' },
    NE: { code: 'NE', name: 'Niger', prefix: '++227', currency_code: 'XOF', img: 'assets/images/flags/NE.png' },
    NG: { code: 'NG', name: 'Nigeria', prefix: '++234', currency_code: 'NGN', img: 'assets/images/flags/NG.png' },
    NU: { code: 'NU', name: 'Niue', prefix: '++683', currency_code: 'NZD', img: 'assets/images/flags/NU.png' },
    NF: { code: 'NF', name: 'Norfolk Island', prefix: '++93', currency_code: 'AUD', img: 'assets/images/flags/NF.png' },
    MP: { code: 'MP', name: 'Northern Mariana Islands', prefix: '++1', currency_code: 'USD', img: 'assets/images/flags/MP.png' },
    NO: { code: 'NO', name: 'Norway', prefix: '++47', currency_code: 'NOK', img: 'assets/images/flags/NO.png' },
    OM: { code: 'OM', name: 'Oman', prefix: '++968', currency_code: 'OMR', img: 'assets/images/flags/OM.png' },
    PK: { code: 'PK', name: 'Pakistan', prefix: '++92', currency_code: 'PKR', img: 'assets/images/flags/PK.png' },
    PW: { code: 'PW', name: 'Palau', prefix: '++680', currency_code: 'USD', img: 'assets/images/flags/PW.png' },
    PS: { code: 'PS', name: 'Palestinian Territory', prefix: '++93', currency_code: 'JOD', img: 'assets/images/flags/PS.png' },
    PA: { code: 'PA', name: 'Panama', prefix: '++507', currency_code: 'PAB', img: 'assets/images/flags/PA.png' },
    PG: { code: 'PG', name: 'Papua New Guinea', prefix: '++675', currency_code: 'PGK', img: 'assets/images/flags/PG.png' },
    PY: { code: 'PY', name: 'Paraguay', prefix: '++595', currency_code: 'PYG', img: 'assets/images/flags/PY.png' },
    PE: { code: 'PE', name: 'Peru', prefix: '++51', currency_code: 'PEN', img: 'assets/images/flags/PE.png' },
    PH: { code: 'PH', name: 'Philippines', prefix: '++63', currency_code: 'PHP', img: 'assets/images/flags/PH.png' },
    PN: { code: 'PN', name: 'Pitcairn', prefix: '++93', currency_code: 'NZD', img: 'assets/images/flags/PN.png' },
    PL: { code: 'PL', name: 'Poland', prefix: '++48', currency_code: 'PLN', img: 'assets/images/flags/PL.png' },
    PT: { code: 'PT', name: 'Portugal', prefix: '++351', currency_code: 'EUR', img: 'assets/images/flags/PT.png' },
    PR: { code: 'PR', name: 'Puerto Rico', prefix: '++1', currency_code: 'USD', img: 'assets/images/flags/PR.png' },
    QA: { code: 'QA', name: 'Qatar', prefix: '++974', currency_code: 'QAR', img: 'assets/images/flags/QA.png' },
    RE: { code: 'RE', name: 'Reunion', prefix: '++262', currency_code: 'EUR', img: 'assets/images/flags/RE.png' },
    RO: { code: 'RO', name: 'Romania', prefix: '++40', currency_code: 'RON', img: 'assets/images/flags/RO.png' },
    RU: { code: 'RU', name: 'Russia', prefix: '++7', currency_code: 'RUB', img: 'assets/images/flags/RU.png' },
    RW: { code: 'RW', name: 'Rwanda', prefix: '++250', currency_code: 'RWF', img: 'assets/images/flags/RW.png' },
    BL: { code: 'BL', name: 'Saint Barthelemy', prefix: '++93', currency_code: 'EUR', img: 'assets/images/flags/BL.png' },
    SH: { code: 'SH', name: 'Saint Helena', prefix: '++290', currency_code: 'GBP', img: 'assets/images/flags/SH.png' },
    KN: { code: 'KN', name: 'Saint Kitts and Nevis', prefix: '++1', currency_code: 'XCD', img: 'assets/images/flags/KN.png' },
    LC: { code: 'LC', name: 'Saint Lucia', prefix: '++1', currency_code: 'XCD', img: 'assets/images/flags/LC.png' },
    MF: { code: 'MF', name: 'Saint Martin', prefix: '++93', currency_code: 'ANG', img: 'assets/images/flags/MF.png' },
    SX: { code: 'SX', name: 'Sint Maarten', prefix: '++1', currency_code: 'ANG', img: 'assets/images/flags/NL.png' },
    PM: { code: 'PM', name: 'Saint Pierre and Miquelon', prefix: '++508', currency_code: 'EUR', img: 'assets/images/flags/PM.png' },
    VC: { code: 'VC', name: 'Saint Vincent and the Grenadines', prefix: '++1', currency_code: 'XCD', img: 'assets/images/flags/VC.png' },
    WS: { code: 'WS', name: 'Samoa', prefix: '++685', currency_code: 'EUR', img: 'assets/images/flags/WS.png' },
    SM: { code: 'SM', name: 'San Marino', prefix: '++378', currency_code: 'EUR', img: 'assets/images/flags/SM.png' },
    ST: { code: 'ST', name: 'Sao Tome and Principe', prefix: '++239', currency_code: 'STD', img: 'assets/images/flags/ST.png' },
    SA: { code: 'SA', name: 'Saudi Arabia', prefix: '++966', currency_code: 'SAR', img: 'assets/images/flags/SA.png' },
    SN: { code: 'SN', name: 'Senegal', prefix: '++221', currency_code: 'XOF', img: 'assets/images/flags/SN.png' },
    RS: { code: 'RS', name: 'Serbia', prefix: '++381', currency_code: 'RSD', img: 'assets/images/flags/RS.png' },
    SC: { code: 'SC', name: 'Seychelles', prefix: '++248', currency_code: 'SCR', img: 'assets/images/flags/SC.png' },
    SL: { code: 'SL', name: 'Sierra Leone', prefix: '++232', currency_code: 'SLL', img: 'assets/images/flags/SL.png' },
    SG: { code: 'SG', name: 'Singapore', prefix: '++65', currency_code: 'SGD', img: 'assets/images/flags/SG.png' },
    SK: { code: 'SK', name: 'Slovakia', prefix: '++421', currency_code: 'SKK', img: 'assets/images/flags/SK.png' },
    SI: { code: 'SI', name: 'Slovenia', prefix: '++386', currency_code: 'EUR', img: 'assets/images/flags/SI.png' },
    SB: { code: 'SB', name: 'Solomon Islands', prefix: '++677', currency_code: 'SBD', img: 'assets/images/flags/SB.png' },
    SO: { code: 'SO', name: 'Somalia', prefix: '++252', currency_code: 'SOS', img: 'assets/images/flags/SO.png' },
    ZA: { code: 'ZA', name: 'South Africa', prefix: '++27', currency_code: 'ZAR', img: 'assets/images/flags/ZA.png' },
    GS: {
      code: 'GS',
      name: 'South Georgia and the South Sandwich Islands',
      prefix: '++93',
      currency_code: 'GBP',
      img: 'assets/images/flags/GS.png',
    },
    ES: { code: 'ES', name: 'Spain', prefix: '++34', currency_code: 'EUR', img: 'assets/images/flags/ES.png' },
    LK: { code: 'LK', name: 'Sri Lanka', prefix: '++94', currency_code: 'LKR', img: 'assets/images/flags/LK.png' },
    SD: { code: 'SD', name: 'Sudan', prefix: '++249', currency_code: 'SDG', img: 'assets/images/flags/SD.png' },
    SR: { code: 'SR', name: 'Suriname', prefix: '++597', currency_code: 'SRD', img: 'assets/images/flags/SR.png' },
    SJ: { code: 'SJ', name: 'Svalbard and Jan Mayen', prefix: '++93', currency_code: 'NOK', img: 'assets/images/flags/SJ.png' },
    SZ: { code: 'SZ', name: 'Swaziland', prefix: '++268', currency_code: 'SZL', img: 'assets/images/flags/SZ.png' },
    SE: { code: 'SE', name: 'Sweden', prefix: '++46', currency_code: 'SEK', img: 'assets/images/flags/SE.png' },
    CH: { code: 'CH', name: 'Switzerland', prefix: '++41', currency_code: 'CHF', img: 'assets/images/flags/CH.png' },
    SY: { code: 'SY', name: 'Syrian Arab Republic', prefix: '++963', currency_code: 'SYP', img: 'assets/images/flags/SY.png' },
    TW: { code: 'TW', name: 'Taiwan, Province of China', prefix: '++886', currency_code: 'TWD', img: 'assets/images/flags/TW.png' },
    TJ: { code: 'TJ', name: 'Tajikistan', prefix: '++992', currency_code: 'TJS', img: 'assets/images/flags/TJ.png' },
    TZ: { code: 'TZ', name: 'Tanzania, United Republic of', prefix: '++255', currency_code: 'TZS', img: 'assets/images/flags/TZ.png' },
    TH: { code: 'TH', name: 'Thailand', prefix: '++66', currency_code: 'THB', img: 'assets/images/flags/TH.png' },
    TL: { code: 'TL', name: 'Timor-Leste', prefix: '++93', currency_code: 'USD', img: 'assets/images/flags/TL.png' },
    TG: { code: 'TG', name: 'Togo', prefix: '++228', currency_code: 'XOF', img: 'assets/images/flags/TG.png' },
    TK: { code: 'TK', name: 'Tokelau', prefix: '++690', currency_code: 'NZD', img: 'assets/images/flags/TK.png' },
    TO: { code: 'TO', name: 'Tonga', prefix: '++676', currency_code: 'TOP', img: 'assets/images/flags/TO.png' },
    TT: { code: 'TT', name: 'Trinidad and Tobago', prefix: '++1', currency_code: 'TTD', img: 'assets/images/flags/TT.png' },
    TN: { code: 'TN', name: 'Tunisia', prefix: '++216', currency_code: 'TND', img: 'assets/images/flags/TN.png' },
    TR: { code: 'TR', name: 'Turkey', prefix: '++90', currency_code: 'TRY', img: 'assets/images/flags/TR.png' },
    TM: { code: 'TM', name: 'Turkmenistan', prefix: '++993', currency_code: 'TMT', img: 'assets/images/flags/TM.png' },
    TC: { code: 'TC', name: 'Turks and Caicos Islands', prefix: '++1', currency_code: 'USD', img: 'assets/images/flags/TC.png' },
    TV: { code: 'TV', name: 'Tuvalu', prefix: '++688', currency_code: 'AUD', img: 'assets/images/flags/TV.png' },
    UG: { code: 'UG', name: 'Uganda', prefix: '++256', currency_code: 'UGX', img: 'assets/images/flags/UG.png' },
    UA: { code: 'UA', name: 'Ukraine', prefix: '++380', currency_code: 'UAH', img: 'assets/images/flags/UA.png' },
    AE: { code: 'AE', name: 'United Arab Emirates', prefix: '++971', currency_code: 'AED', img: 'assets/images/flags/AE.png' },
    GB: { code: 'GB', name: 'United Kingdom', prefix: '++44', currency_code: 'GBP', img: 'assets/images/flags/GB.png' },
    US: { code: 'US', name: 'United States', prefix: '++1', currency_code: 'USD', img: 'assets/images/flags/US.png' },
    UM: {
      code: 'UM',
      name: 'United States Minor Outlying Islands',
      prefix: '++1',
      currency_code: 'USD',
      img: 'assets/images/flags/UM.png',
    },
    UY: { code: 'UY', name: 'Uruguay', prefix: '++598', currency_code: 'UYU', img: 'assets/images/flags/UY.png' },
    UZ: { code: 'UZ', name: 'Uzbekistan', prefix: '++998', currency_code: 'UZS', img: 'assets/images/flags/UZ.png' },
    VU: { code: 'VU', name: 'Vanuatu', prefix: '++678', currency_code: 'VUV', img: 'assets/images/flags/VU.png' },
    VE: { code: 'VE', name: 'Venezuela', prefix: '++58', currency_code: 'VEF', img: 'assets/images/flags/VE.png' },
    VN: { code: 'VN', name: 'Viet Nam', prefix: '++84', currency_code: 'VND', img: 'assets/images/flags/VN.png' },
    VG: { code: 'VG', name: 'Virgin Islands, British', prefix: '++93', currency_code: 'USD', img: 'assets/images/flags/VG.png' },
    VI: { code: 'VI', name: 'Virgin Islands, U.S.', prefix: '++93', currency_code: 'USD', img: 'assets/images/flags/VI.png' },
    WF: { code: 'WF', name: 'Wallis and Futuna', prefix: '++681', currency_code: 'XPF', img: 'assets/images/flags/WF.png' },
    EH: { code: 'EH', name: 'Western Sahara', prefix: '++93', currency_code: 'MAD', img: 'assets/images/flags/EH.png' },
    YE: { code: 'YE', name: 'Yemen', prefix: '++967', currency_code: 'YER', img: 'assets/images/flags/YE.png' },
    ZM: { code: 'ZM', name: 'Zambia', prefix: '++260', currency_code: 'ZMK', img: 'assets/images/flags/ZM.png' },
    ZW: { code: 'ZW', name: 'Zimbabwe', prefix: '++263', currency_code: 'ZWD', img: 'assets/images/flags/ZW.png' },
  };
}
